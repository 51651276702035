import React from "react"
import pinterest from "../assets/images/pinterest.jpg"
import facebook from "../assets/images/facebook.jpg"
import instagram from "../assets/images/instagram.jpg"
import google from "../assets/images/google.jpg"

function ColumnsWorksWith() {

  return (
    <div className="container-fluid">
      <div className={`row four-columns`}>
        <h2 className="text-center">Our feed works with</h2>
      </div>

      <div className={`row four-columns justify-content-around`}>
        <div className={`col-lg-5 col-md-5 my-auto px-lg-3`}>
          <h3>Pinterest</h3>
          <p className="workswith-subheadline">Shop Tab - Dynamic Shopping Ads - Collection Ads</p>
          <p>The Upfeed product feed in combination with Pinterest enables your free Shop tab, and sets you up to start advertising with shoppable pins, dynamic shopping ads and collection ads on Pinterest.</p>
        </div>
        <div className={`col-lg-5 col-md-6`}>
          <img src={pinterest} className={`img-fluid workswith`} alt="Pinterest Product Feed" />
        </div>
      </div>

      <div className={`row four-columns justify-content-around`}>
        <div className={`col-lg-5 col-md-6`}>
          <img src={facebook} className={`img-fluid workswith`} alt="Facebook Product Feed" />
        </div>
        <div className={`col-lg-6 col-md-6 my-auto px-lg-5 px-md-3`}>
          <h3>Facebook</h3>
          <p className="workswith-subheadline">Dynamic Product Ads - Collection Ads - Facebook Shop</p>
          <p>Take advantage of the largest social network with a Shop page or advertise your products to millions of users using dynamic product ads and collection ads.</p>
        </div>
      </div>
      <div className={`row four-columns justify-content-around`}>
        <div className={`col-lg-6 col-md-6 my-auto px-lg-5 px-md-3`}>
          <h3>Instagram</h3>
          <p className="workswith-subheadline">Shoppable Posts - Dynamic Product Ads - Collection Ads</p>
          <p>Increase revenue from Instagram with Shoppable posts, or through advertising using dynamic product ads or collection ads. Increase your product visibility and maximize profits.</p>
        </div>
        <div className={`col-lg-5 col-md-6`}>
          <img src={instagram} className={`img-fluid workswith`} alt="Instagram Product Feed" />
        </div>
      </div>
      <div className={`row four-columns justify-content-around`}>
        <div className={`col-lg-5 col-md-6`}>
          <img src={google} className={`img-fluid workswith`} alt="Google Product Feed" />
        </div>
        <div className={`col-lg-6 col-md-6 my-auto px-lg-5 px-md-3`}>
          <h3>Google</h3>
          <p className="workswith-subheadline">Google Shopping - Product Listing Ads</p>
          <p>Take advantage of free listings in the Google Shopping tab, and increase your revenue further with product listing ads through Google Ads. All powered by the Upfeed product feed.</p>
        </div>
      </div>
    </div>


  )

}

export default ColumnsWorksWith;