import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import ColumnsWorksWith from "../components/ColumnsWorksWith"
import ColumnsShopifyStores from "../components/ColumnsShopifyStores"
import TrustedByBanner from "../components/TrustedByBanner"
import illustration from "../assets/images/upfeed-illustration.png"

const HomePage = () => (
  <Layout>
    <div className="container">
      <div className="row row-spacing-6">
        <div className="col-md-6">
          <h1 className="headline">
            <u>Maximize Revenue</u> from Dynamic Ad Campaigns
          </h1>
          <p className="subheadline">
            Improve your shopping feed for Pinterest, Facebook, Instagram and
            Google
          </p>
          <div class={`ms-lg-5 ps-lg-5 pt-2`}>
            <Link
              to={"https://apps.shopify.com/facebook-shopping-feed"}
              target="_blank"
              className="text-center"
            >
              <button type="button" class="btn btn-primary btn-lg trial-btn">
                Start Free Trial
              </button>
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <img src={illustration} className="hero-illustration img-fluid" />
        </div>
      </div>
      <TrustedByBanner />
      <ColumnsShopifyStores />
    </div>
    <ColumnsWorksWith />
  </Layout>
)

export default HomePage
