import React from "react"

function ColumnsShopifyStores() {
  return (
    <div className={`row four-columns`}>
      <h2 className="text-center">Upfeed helps 500+ Shopify stores to</h2>
      <div className="col-lg-3 col-md-6">
        <h3>Showcase Products</h3>
        <p>Showcase all your products on all social media platforms with ease. Accurate product information, up-to-date pricing (including sales pricing) and availability will increase click-through rates and conversions.</p>
      </div>
      <div className="col-lg-3 col-md-6">
        <h3>Increase Ad Revenue</h3>
        <p>Upfeed feeds follow the latest platform requirements and provides extra information to ‘feed’ ad algorithms. Your feed is fully optimized to get the best results from dynamic ad campaigns. </p>
      </div>
      <div className="col-lg-3 col-md-6">
        <h3>Cross-Platform</h3>
        <p>We use a single feed format optimized for the 4 major ad and product platforms: Google, Facebook, Instagram and Pinterest. Upfeed is the one-stop solution to end your feed headaches.</p>
      </div>
      <div className="col-lg-3 col-md-6">
        <h3>Auto-Sync</h3>
        <p>We auto-sync your store's products multiple times a day and update your product feed at least once a day. With Upfeed your feed is always up-to-date with the latest product information, pricing changes and stock availability.</p>
      </div>
    </div>
  )
}

export default ColumnsShopifyStores;